import React from "react";
import QrReader from "react-qr-reader";
import "./style.css";
import Spinner from "../../Spinner";
import Swal from "sweetalert2";
import jQuery from "jquery";
import Guest from "./Guest";
import ReactDOM from "react-dom";
import { link_api } from "../../Config";

export default class Scanner extends React.Component {
  user = null;
  evento = null;

  render() {
    const userStorage = localStorage.getItem("app-user");
    if (userStorage) {
      this.user = JSON.parse(userStorage);
      this.evento = this.user.evento;
    }
    return (
      <div className="App">
        <Spinner />
        <div className="info">
          <p>{this.evento.nome}</p>
        </div>
        <QrReader
          delay={1000}
          onError={this.handleError}
          onScan={(data) => this.buscar(data)}
          className="QrReader"
        />
        <p>Aponte a câmera para o QRCode</p>
        <div id="guest"></div>
      </div>
    );
  }

  QRCodeDecode = function (cod) {
    let result = [];
    let dados = cod.split("\n");
    if (dados.length > 1 && dados[1] !== "") {
      for (let item in dados) {
        let linha = dados[item].split(":");
        result[linha[0]] = linha[1];
      }
    } else {
      result = {
        UID: dados[0],
      };
    }
    if (result.UID || result.CONF) {
      return result.UID ? result.UID : result.CONF;
    } else {
      Swal.fire("Erro ao decodificar QRCode", null, "error");
    }
  };

  buscar(cod) {
    let login = this.user;
    if (cod) {
      let id = this.QRCodeDecode(cod);
      if (id) {
        jQuery(".page-loader-wrapper").fadeIn();
        jQuery.ajax({
          url: link_api + "getCode.php",
          type: "POST",
          data: { user: login.usuario, token: login.token, code: id },
          dataType: "json",
          success: function (result) {
            jQuery(".page-loader-wrapper").fadeOut();
            if (result.success) {
              ReactDOM.render(
                Guest(result.data, login),
                document.getElementById("guest")
              );
            } else if (result.error) {
              console.log(result.error);
              Swal.fire("Oops...", result.error, "error");
            } else {
              console.log(result);
              Swal.fire("Oops...", result, "error");
            }
          },
          error: function (error) {
            jQuery(".page-loader-wrapper").fadeOut();
            Swal.fire("Oops...", error, "error");
            console.log(error);
          },
        });
      }
    }
  }

  handleError(err) {
    console.error(err);
  }
}
