import React from "react";
import Routes from "../components/Routes";
import "./App.css";

const App = () => (
  <main className="App">
    <Routes />
  </main>
);

export default App;
