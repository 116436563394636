import React from "react";
import alfa from "./alfa.svg";

const Spinner = () => (
  <div className="page-loader-wrapper">
    <div className="loader">
      <img src={alfa} className="spinner" alt="spinner" />
      <p>Aguarde um momento...</p>
    </div>
  </div>
);

export default Spinner;
