import React from "react";
import Swal from "sweetalert2";
import jQuery from "jquery";
import { link_api } from "../../Config";

export default function Guest(guest, user) {
  let id = guest.id;
  let usuario = user.usuario;
  let token = user.token;
  let nome = guest.nome;
  let email = guest.email;
  let foto = guest.extra._FOTO;

  const confirmar = function (id, usuario, token) {
    jQuery(".page-loader-wrapper").fadeIn();
    jQuery.ajax({
      url: link_api + "confirm.php",
      type: "POST",
      data: { user: usuario, token: token, id: id },
      dataType: "json",
      success: function (result) {
        jQuery(".page-loader-wrapper").fadeOut();
        if (result.success) {
          Swal.fire("CONFIRMADO", "", "success");
        } else if (result.error) {
          console.log(result.error);
          Swal.fire("Oops...", result.error, "error");
        } else {
          console.log(result);
          Swal.fire("Oops...", result, "error");
        }
      },
      error: function (error) {
        jQuery(".page-loader-wrapper").fadeOut();
        Swal.fire("Oops...", error, "error");
        console.log(error);
      },
    });
  };

  return (
    <div className="guestview">
      {foto && <img src={foto} alt="foto" />}
      <div>
        {nome}
        <br />
        {email}
        <br />
        <button
          className="btn btn-success"
          onClick={() => confirmar(id, usuario, token)}
        >
          CONFIRMAR
        </button>
      </div>
    </div>
  );
}
