import React from "react";

import { ErrorMessage, Formik, Form, Field } from "formik";
import * as yup from "yup";
import jQuery from "jquery";
import { history } from "../../history";
import { link_api } from "../../Config";
import alfa from "../../alfa.svg";

import "./style.css";

const Login = () => {
  const handleSubmit = (values) => {
    jQuery
      .ajax({
        method: "post",
        url: link_api + "login.php",
        data: {
          user: values.usuario,
          pass: values.password,
        },
      })
      .then((response) => {
        if (response.success) {
          const { data } = response;
          if (data) {
            const jsonUser = JSON.stringify(data);
            localStorage.setItem("app-user", jsonUser);
            history.push("/");
          }
        } else if (response.error) {
          console.error(response.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const validations = yup.object().shape({
    usuario: yup.string().required(),
    password: yup.string().min(6).required(),
  });
  return (
    <div className="Login-Container">
      <img className="logo" src={alfa} alt="logo" />
      <h1>CHECKINQRCODE</h1>
      <Formik
        initialValues={{}}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        <Form className="Login">
          <div className="Login-Group">
            <Field name="usuario" className="Login-Field" />
            <ErrorMessage
              component="span"
              name="usuario"
              className="Login-Error"
            />
          </div>
          <div className="Login-Group">
            <Field name="password" className="Login-Field" />
            <ErrorMessage
              component="span"
              name="password"
              className="Login-Error"
            />
          </div>
          <button className="Login-Btn" type="submit">
            Login
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
