import React from "react";
import { Router, Switch, Route } from "react-router";

import Login from "../pages/login";
import Scanner from "../pages/scanner";
import NoteFound from "./NotFound";
import PrivateRoute from "./PrivateRoute";

import { history } from "../history";

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route component={Login} exact path="/login" />
      <PrivateRoute component={Scanner} exact path="/" />
      <PrivateRoute component={NoteFound} />
    </Switch>
  </Router>
);

export default Routes;
